import i18n from "@/i18n";
import BfIcon from "../../icon/BfIcon";
import BFOverlay from "../whisper/BFOverlay";
// import "./ErrorInfo.scss";

interface ErrorInfoProps {
  errorMessage?: string;
}
const ErrorInfo = (props: ErrorInfoProps) => {
  return (
    <BFOverlay
      trigger="hover"
      speaker={
        <div className={`padding-10 __color-negative`}>
          {props.errorMessage ||
            i18n.t(
              "common:ErrorInfo.ErrorMessage",
              "Es ist ein Fehler aufgetreten."
            )}
        </div>
      }
    >
      <div>
        <BfIcon
          className={`__color-negative`}
          data={"on-error-sad"}
          type="light"
          size="xxs"
        />
      </div>
    </BFOverlay>
  );
};

export default ErrorInfo;
