import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import MQ from "../../../../../../utils/MatchQueryUtils";
import { AssetCashBudgetEntry } from "../../../model/CashBudgetEntry";
import CBIdlyConnect from "./CBIdlyConnect";
import "./CBIdlyConnect.scss";
import CBIdlyConnectRentalSuggestion from "./CBIdlyConnectRentalSuggestion";
import CBIdlyConnectService, {
  RentalAgreementSuggestion,
} from "./CBIdlyConnectService";

interface CBIdlyConnectRentalProps {
  booking: AssetCashBudgetEntry;
  initialOpen?: boolean;
}
const CBIdlyConnectRental = (props: CBIdlyConnectRentalProps) => {
  return (
    <CBIdlyConnect
      assetType={AssetTypes.Rental.RentalAgreement}
      mapSuggestion={CBIdlyConnectService.mapSuggestionRental}
      matchQuery={MQ.and(
        MQ.eq("data.entity", props.booking.data.entity)

        // removed because a lot of bookings are linked to the wrong object
        // when(
        //   hasValue(props.booking.data.objectId),
        //   MQ.eq("data.objectId", props.booking.data.objectId)
        // )
      )} // todo check for rent > 0 etc
      className="cb-idly-connect-rental"
      title={i18n.t("cb:CBIdlyConnect.rental.title", "Mietvertrag verknüpfen")}
      booking={props.booking}
      initialOpen={props.initialOpen}
      connectActionLabel={i18n.t(
        "cb:CBIdlyConnect.rental.connect",
        "Mietvertrag verknüpfen"
      )}
      isConnected={(suggestion: RentalAgreementSuggestion) =>
        props.booking.data.linkedAsset?.some(
          (e) => e.assetId === suggestion.asset._id
        )
      }
      disconnectActionLabel={i18n.t(
        "cb:CBIdlyConnect.rental.disconnect",
        "Verknüpfung aufheben"
      )}
      disconnectActionPromise={async (suggestion: RentalAgreementSuggestion) =>
        await CBIdlyConnectService.unlink(
          props.booking._id,
          AssetTypes.Rental.RentalAgreement,
          suggestion.asset._id
        )
      }
      connectActionPromise={async (suggestion: RentalAgreementSuggestion) =>
        await CBIdlyConnectService.link(
          props.booking._id,
          AssetTypes.Rental.RentalAgreement,
          suggestion.asset._id
        )
      }
      manualActionLabel={i18n.t(
        "cb:CBIdlyConnect.rental.manual",
        "Mietvertrag suchen"
      )}
      allowManualSearch
      // manualActionPromise={async () => {
      //   // open search booking action
      //   // DataBusDefaults.route({
      //   //   route: `__link-invoice-to-booking/${props.booking._id}`,
      //   //   append: true,
      //   // });
      // }}
      renderSuggestion={(suggestion: RentalAgreementSuggestion) => (
        <CBIdlyConnectRentalSuggestion suggestion={suggestion} />
      )}
      renderSuggestionHint={CBIdlyConnectService.getSuggestionHintRental}
      suggestionPromise={async (booking: AssetCashBudgetEntry) =>
        await CBIdlyConnectService.fetchSuggestionsForRental(props.booking)
      }
      ruleActionData={(suggestion: RentalAgreementSuggestion) => ({
        assetDescription: `${suggestion.asset.data.id} ${suggestion.asset.data.displayName}`,
        assetName: i18n.t("cb:CBIdlyConnect.rental.AssetName", "Mietvertrag"),
        assetType: AssetTypes.Rental.RentalAgreement,
      })}
    />
  );
};

export default CBIdlyConnectRental;
